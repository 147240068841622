import Vue from "vue";
import Router from "vue-router";
import RF from "./../services/RouterFunctions";
import TC from "./../services/TokenControl";
import Check from "./../services/CheckV2";
Vue.use(Router);

// route-level code splitting
const useComponent = (dir, component) => () =>
  import(
    /* webpackChunkName: "[request]" */ `./../views/${dir}${component}.vue`
  );

const ref = Check.getVersion()
sessionStorage.setItem("ref", ref);

let url = process.env.VUE_APP_SITE;
if (ref == "v2") {
  url = process.env.VUE_APP_SITE_V2;
}

// ROUTES
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  fallback: false,
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      name: "home",
      redirect: { name: "registrationData" }
    },
    {
      path: "/login",
      name: "login",
      component: useComponent("", "Login"),
    },
    {
      path: "/recover-password",
      name: "recoverPassword",
      component: useComponent("", "RecoverPassword"),
    },
    {
      path: "/dados-cadastrais",
      name: "registrationData",
      component: useComponent("registration-data/", "Main"),
      redirect: { name: "indexUser" },
      titulo: "Dados Cadastrais",
      menu: true,
      order: 1,
      icone: "mdi-account-outline",
      beforeEnter: RF.requireAuth,
      children: [
        {
          path: "dados-do-usuario",
          name: "mainUser",
          component: useComponent("registration-data/user/", "Main"),
          redirect: { name: "indexUser" },
          titulo: "Dados do Usuário",
          menu: true,
          children: [
            {
              path: "",
              name: "indexUser",
              component: useComponent("registration-data/user/", "IndexUser"),
              titulo: "Dados do Usuário",
            },
            {
              path: "alterar",
              name: "editUser",
              component: useComponent("registration-data/user/", "EditUser"),
              titulo: "Editar Usuário",
            },
            {
              path: "alterar-pj",
              name: "editUserPJ",
              component: useComponent("registration-data/user/", "EditUserPJ"),
              titulo: "Editar Usuário",
            },
            {
              path: "alterar-pf",
              name: "editUserPF",
              component: useComponent("registration-data/user/", "EditUserPF"),
              titulo: "Editar Usuário",
            },
          ],
        },

        {
          path: "contato",
          name: "mainContact",
          component: useComponent("registration-data/contact/", "Main"),
          redirect: { name: "indexContact" },
          titulo: "Contato",
          menu: false,
          children: [
            {
              path: "",
              name: "indexContact",
              component: useComponent(
                "registration-data/contact/",
                "IndexContact"
              ),
              titulo: "Contato",
            },
            {
              path: "criar",
              name: "addContact",
              component: useComponent(
                "registration-data/contact/",
                "AddContact"
              ),
              titulo: "Editar Contato",
            },
          ],
        },
        {
          path: "dados-de-acesso",
          name: "mainAccess",
          component: useComponent("registration-data/access/", "Main"),
          redirect: { name: "indexAccess" },
          titulo: "Dados de Acesso",
          menu: true,
          children: [
            {
              path: "",
              name: "indexAccess",
              component: useComponent(
                "registration-data/access/",
                "IndexAccess"
              ),
              titulo: "Acesso",
            },
            {
              path: "alterar",
              name: "editAccess",
              component: useComponent(
                "registration-data/access/",
                "EditAccess"
              ),
              titulo: "Editar Acesso",
            },
          ],
        },
        {
          path: "endereco",
          name: "indexAddress",
          component: useComponent("registration-data/address/", "IndexAddress"),
          titulo: "Endereço",
          menu: true,
        },
        // {
        //   path: "endereco/criar",
        //   name: "addAddress",
        //   component: useComponent("registration-data/address/", "AddAddress"),
        //   titulo: "Adicionar Endereço",
        // },
        // {
        //   path: "endereco/editar/:id",
        //   name: "editAddress",
        //   component: useComponent("registration-data/address/", "EditAddress"),
        //   titulo: "Editar Endereço",
        // },
        {
          path: "certificado-detran",
          name: "indexCertDetran",
          component: useComponent("registration-data/user/", "UploadCertDetran"),
          titulo: "Certificado Detran",
          meta: {
            enableCerDetran: (enable) => enable
          },
          menu: true
        }
      ],
    },
    {
      path: "/lotes",
      name: "mainLots",
      component: useComponent("after-sale/", "Main"),
      redirect: { name: "myLots" },
      titulo: "Meus Lotes",
      menu: true,
      order: 5,
      icone: "mdi-currency-usd",
      beforeEnter: RF.requireAuth,
      children: [
        {
          path: "meus-lotes",
          name: "myLots",
          component: useComponent("after-sale/", "MyLots"),
          titulo: "Meus Lotes",
        },
        {
          path: "lotes-representados",
          name: "myLotsRepresented",
          component: useComponent("after-sale/", "MyLotsRepresented"),
          titulo: "Lotes Representados",
        },
        {
          path: "contra-oferta",
          name: "lotWithCounterOffer",
          component: useComponent("after-sale/", "Counteroffer"),
          titulo: "Contra Oferta",
        },
        {
          path: "pos-venda",
          name: "mainAfterSale",
          component: useComponent("after-sale/steps/", "Main"),
          redirect: { name: "afterSalesDelegation" },
          titulo: "Pós-Venda",
          children: [
            {
              path: "procuracao",
              name: "afterSalesDelegation",
              component: useComponent("after-sale/steps/", "Delegation"),
              menu: false,
            },
            {
              path: "endereco",
              name: "afterSalesAddress",
              component: useComponent("after-sale/steps/", "AddressNote"),
              menu: false,
            },
            {
              path: "upload",
              name: "afterSalesUpload",
              component: useComponent("after-sale/steps/", 'UploadDocument'),
              menu: false,
            },
            {
              path: "upload/storage",
              name: "afterSalesUploadStorage",
              component: useComponent("after-sale/steps/", 'UploadDocumentFromStorage'),
              menu: false,
            },
            {
              path: "delivery",
              name: "afterSalesDelivery",
              component: useComponent("after-sale/steps/", "Delivery"),
              menu: false,
            },
            {
              path: "pagamento",
              name: "afterSalesPayment",
              component: useComponent("after-sale/steps/", "Payment"),
              menu: false,
              children: [
                {
                  path: "credit",
                  name: "afterSalesPayment_credit",
                  component: useComponent("after-sale/payments/", "Credit"),
                  menu: false
                },
                {
                  path: "pix",
                  name: "afterSalesPayment_pix",
                  component: useComponent("after-sale/payments/", "Pix"),
                  menu: false
                },
                {
                  path: "ted",
                  name: "afterSalesPayment_ted",
                  component: useComponent("after-sale/payments/", "Ted"),
                  menu: false
                },
                {
                  path: "billet",
                  name: "afterSalesPayment_billet",
                  component: useComponent("after-sale/payments/", "Billet"),
                  menu: false
                },
              ]
            },
          ],
        },
        {
          path: "finalizado",
          name: "afterSalesFinished",
          component: useComponent("after-sale/steps/", "Finished"),
          menu: false,
        },
        {
          path: "documentos",
          name: "afterSalesDocuments",
          component: useComponent("after-sale/steps/", "Documents"),
          menu: false,
        },
      ],
    },
    {
      path: "/agendamento",
      name: "scheduling",
      component: useComponent("scheduling/", "Main"),
      redirect: { name: "indexScheduling" },
      titulo: "Agendamento de Retirada",
      menu: true,
      order: 6,
      icone: "mdi-calendar-outline",
      beforeEnter: RF.requireAuth,
      children: [
        {
          path: "",
          name: "indexScheduling",
          component: useComponent("scheduling/", "IndexScheduling"),
        },
        {
          path: "agendamentos-agendar",
          name: "noticeAvailableLot",
          component: useComponent("scheduling/", "NoticeAvailableLot"),
        },
        {
          path: "agendamentos",
          name: "noticeWithoutLot",
          component: useComponent("scheduling/", "NoticeWithoutLot"),
        },
        {
          path: "historico",
          name: "withdrawalHistory",
          component: useComponent("scheduling/", "WithdrawalHistory"),
        },
        {
          path: "lotes-disponiveis",
          name: "lotsAvailable",
          component: useComponent("scheduling/", "LotsAvailable"),
        },
        {
          path: "quem-vai-retirar",
          name: "whoWilldraw",
          component: useComponent("scheduling/", "WhoWilldraw"),
        },
        {
          path: "alterar-quem-vai-retirar",
          name: "alterWhoWilldraw",
          component: useComponent("scheduling/", "AlterWhoWilldraw"),
        },
        {
          path: "adicionar-responsavel",
          name: "addUser",
          component: useComponent("scheduling/", "AddUser"),
        },
        {
          path: "tipo-retirada",
          name: "withdrawalType",
          component: useComponent("scheduling/", "WithdrawalType"),
        },
        {
          path: "escolha-da-data",
          name: "scheduleDateTime",
          component: useComponent("scheduling/", "ScheduleDateTime"),
        },
        {
          path: "protocolo",
          name: "scheduledWithdrawal",
          component: useComponent("scheduling/", "ScheduledWithdrawal"),
        },
      ],
    },
    {
      path: '/procuracoes',
      name: 'myDelegations',
      component: useComponent('delegations/', 'MyDelegations'),
      redirect: { name: 'myCreatedDelegations' },
      titulo: 'Procurações',
      menu: true,
      order: 2,
      icone: 'mdi-thumb-up-outline',
      beforeEnter: RF.requireAuth,
      children: [
        // {
        //   path: '',
        //   name: 'indexDelegation',
        //   component: useComponent('delegations/', 'IndexDelegation')
        // },
        {
          path: 'criadas',
          name: 'myCreatedDelegations',
          component: useComponent('delegations/', 'MyCreatedDelegations')
        },
        {
          path: 'recebidas',
          name: 'myReceivedDelegations',
          component: useComponent('delegations/', 'MyReceivedDelegations')
        },
        {
          path: 'editar/recebidas',
          name: 'editReceivedDelegation',
          component: useComponent('delegations/', 'EditReceivedDelegation')
        },
        {
          path: 'usuario',
          name: 'searchUser',
          component: useComponent('delegations/', 'SearchUser')
        },
        {
          path: 'criar',
          name: 'createDelegation',
          component: useComponent('delegations/', 'CreateDelegation')
        },
        {
          path: 'termo',
          name: 'termDelegation',
          component: useComponent('delegations/', 'TermDelegation')
        },
      ]
    },
    {
      path: "/sedex",
      name: "sedex",
      component: useComponent("sedex/", "Main"),
      redirect: { name: "indexSedex" },
      titulo: "Sedex",
      menu: true,
      order: 7,
      icone: "mdi-email-outline",
      beforeEnter: RF.requireAuth,
      children: [
        {
          path: "",
          name: "indexSedex",
          component: useComponent("sedex/", "IndexSedex"),
        },
        {
          path: "documento",
          name: "chooseDocumentSedex",
          component: useComponent("sedex/", "ChooseDocumentSedex"),
        },
        {
          path: "endereco",
          name: "addressSedex",
          component: useComponent("sedex/", "AddressSedex"),
        },
      ],
    },
    {
      path: "/favorito",
      name: "favorite",
      component: useComponent("favorite/", "Main"),
      redirect: { name: "indexFavorite" },
      titulo: "Meus Favoritos",
      menu: true,
      order: 3,
      icone: "mdi-heart-outline",
      beforeEnter: RF.requireAuth,
      children: [
        {
          path: "",
          name: "indexFavorite",
          component: useComponent("favorite/", "IndexFavorite"),
        },
      ],
    },
    {
      path: "/em-disputa",
      name: "disputedLot",
      component: useComponent("disputed-lot/", "Main"),
      redirect: { name: "indexDisputedLot" },
      titulo: "Lotes em Disputa",
      menu: true,
      order: 4,
      icone: "mdi-cart-outline",
      beforeEnter: RF.requireAuth,
      children: [
        {
          path: "",
          name: "indexDisputedLot",
          component: useComponent("disputed-lot/", "IndexDisputedLot"),
        },
      ],
    },
    {
      path: "/documentos",
      name: "documents",
      component: useComponent("document/", "Main"),
      redirect: { name: "indexDocument" },
      titulo: "Modelo de Documentos",
      menu: true,
      order: 8,
      icone: "mdi-note-multiple-outline",
      beforeEnter: RF.requireAuth,
      children: [
        {
          path: "",
          name: "indexDocument",
          component: useComponent("document/", "IndexDocument"),
        },
      ],
    },
    {
      // href: "https://www.sodresantoro.com.br",
      href: url,
      path: "",
      name: "site",
      titulo: "Voltar ao Site",
      menu: true,
      order: 9,
      icone: "mdi-chevron-double-left",
      beforeEnter: RF.requireAuth,
    },
    // {
    //   path: "https://www.sodresantoro.com.br/favoritos//",
    //   name: "favorites",
    //   titulo: "Favoritos",
    //   menu: true,
    //   icone: "mdi-star-outline",
    //   beforeEnter: RF.requireAuth,
    // },
    // {
    //   path: "https://www.sodresantoro.com.br/meuslotes//",
    //   name: "lots",
    //   titulo: "Lotes Participando",
    //   menu: true,
    //   icone: "mdi-pokeball",
    //   beforeEnter: RF.requireAuth,
    // },
    {
      path: "/sair",
      name: "logout",
      titulo: "Sair",
      menu: true,
      order: 10,
      icone: "mdi-power",
      beforeEnter: RF.logout,
    },
    {
      path: "*",
      name: 'Page404',
      component: useComponent("errors/", 'Page404'),
    }
  ],
});

router.beforeEach((to, from, next) => {
  TC.validateToken();
  next();
});

export default router;
