<template>
  <v-list
    id="sidebarLinks"
    dense
  >
    <template v-for="item in newList">
      <v-list-group
        v-if="item.children && !mini"
        :key="item.text"
        v-model="item.model"
      >
        <template v-slot:activator>
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="(child, i) in item.children"
          :key="i"
          link
          :to="{name: child.routeName}"
        >
          <v-list-item-action v-if="child.icon">
            <v-icon>{{ child.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ child.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-item
        v-else-if="item.href != ''"
        :key="item.text"
        link
        :href="item.href"
      >
        <v-list-item-action>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-else
        :key="item.text"
        link
        :to="{name: item.routeName}"
      >
        <v-list-item-action>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
export default {
  name: "SidebarLinks",
  components: {},
  props: {
    links: {
      required: true,
      type: Array
    },
    mini: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      example: [
        { icon: "mdi-contacts", text: "Contacts" },
        { icon: "mdi-history", text: "Frequently contacted" },
        { icon: "mdi-content-copy", text: "Duplicates" },
        {
          icon: "mdi-chevron-up",
          "icon-alt": "mdi-chevron-down",
          text: "Labels",
          model: false,
          children: [{ icon: "mdi-plus", text: "Create label" }]
        },
        {
          icon: "mdi-chevron-up",
          "icon-alt": "mdi-chevron-down",
          text: "More",
          model: false,
          children: [
            { text: "Import" },
            { text: "Export" },
            { text: "Print" },
            { text: "Undo changes" },
            { text: "Other contacts" }
          ]
        },
        { icon: "mdi-cog", text: "Settings" },
        { icon: "mdi-message", text: "Send feedback" },
        { icon: "mdi-help-circle", text: "Help" },
        { icon: "mdi-cellphone-link", text: "App downloads" },
        { icon: "mdi-keyboard", text: "Go to the old version" }
      ]
    };
  },
  computed: {
    newList() {
      return this.makeLinks(this.links);
    },
  },
  methods: {
    makeLinks(links) {
      const self = this;
      let newList = [];
      links.forEach(element => {
        let visibleCertDetranMenu = true;
        if ('meta' in element && 'enableCerDetran' in element.meta && this.$store.getters.getUserAddress != null) {
          const ufUser = this.$store.getters.getUserAddress.state.toLowerCase();
          const enableMenuCertDetran = this.$store.state.user.isJuridic && ufUser != 'sp';
          visibleCertDetranMenu = element.meta.enableCerDetran(enableMenuCertDetran);
        }

        if ("titulo" in element && element.menu === true && visibleCertDetranMenu === true) {
          // console.log('element.icone: ', element.icone)

          let item = {
            icon: element.icone,
            text: element.titulo,
            path: element.path,
            routeName: element.name,
            href: element.href ? element.href : '',
            order: 'order' in element ? element.order : 99999
          };

          if (
            "children" in element &&
            element.children.filter(route => route.menu).length
          ) {
            item["model"] = false;
            if (this.$router.history.current.path.split('/')[1] === element.path.split('/')[1]) {
              item["model"] = true;
            }
            item["children"] = self.makeLinks(element.children);
          }
          newList.push(item);
        }
      });
      return newList.sort(function (a,b) {
        if (a.order > b.order) return 1
        if (a.order < b.order) return -1
      });
    }
  }
};
</script>

<style lang="scss">
@import "./../assets/sass/colors.scss";

#sidebarLinks {
  .v-list-item {
    .v-list-item__title {
      font-size: 16px !important;
      color: $blue3 !important;
    }
    .v-list-item__action {
      margin-right: 5px !important;
      .v-icon {
        color: $blue3 !important;
      }
    }
  }

  .v-list-item--active {
    color: $green1;
    .v-list-item__content {
      .v-list-item__title {
        color: $green1 !important;
      }
    }
    .v-list-item__action {
      .v-icon {
        color: $green1 !important;
      }
    }
  }

  .v-list-group {
    .v-list-group__items {
      background-color: #eee;
      a {
        .v-list-item__content {
          padding-left: 11px;
        }
      }
    }
  }
}
</style>
