export default {
  getDelegation(state) {
    return state.delegation
  },
  getAlert(state) {
    return state.alert
  },
  getUserAddress(state) {
    return state.userAddress;
  }
}
