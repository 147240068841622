import Api from '../Api'

class UserService {
  async getMe() {
    let endpoint = "/user/me";

    return await Api.get(endpoint);
  }

  async getUserDelegationByCpf(cpf, birthDate, recaptcha) {
    let endpoint = `/delegation/user?cpf=${cpf}&birthDate=${birthDate}&recaptcha=${recaptcha}`

    return await Api.get(endpoint);
  }

  async getContact() {
    let endpoint = "/user/contact";

    return await Api.get(endpoint);
  }

  async deleteContact(id) {
    let endpoint = "/user/contact/" + id;

    return await Api.delete(endpoint);
  }

  async patchAlterPassword(params) {
    let endpoint = "/user/password";

    return await Api.patch(endpoint, params);
  }

  async update(params) {
    let endpoint = "/user/" + params['id'];

    return await Api.put(endpoint, params);
  }

  async resendLinkActiveEmail(userId) {
    let endpoint = "/user/activate-email/" + userId;

    return await Api.get(endpoint);
  }

  async getFavorite(page) {
    let endpoint = `/user/favorites/?page=${page}`;

    return await Api.get(endpoint);
  }

  async getDisputedLot(page) {
    let endpoint = `/user/disputed-lot/?page=${page}`;

    return await Api.get(endpoint);
  }

  async storeQueueScraping(formData) {
    return await Api.post(`/user/cert-detran`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  async getCertDetran() {
    return await Api.get('/user/cert-detran');
  }
}

export default UserService
