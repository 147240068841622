import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

//modules
import Lot from "./modules/module/lot";
import AfterSales from "./modules/module/afterSales";
import Delivery from './modules/module/delivery';
import Payment from './modules/module/payment';
import uploadDocuments from './modules/module/uploadDocuments';
import SalaryOccupation from './modules/module/salaryOccupation'

import createPersistedState from 'vuex-persistedstate'
import certDetran from './modules/module/certDetran'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    userAddress: {},
    delegation: {},
    alert: {
      show: false,
      title: 'Aviso',
      message: '',
      type: 'default'
    },
    notify: {
      show: false,
      message: '',
      color: 'success',
      duration: 6000,
    },
    error: {
      login: {
        message: "",
        show: false
      }
    },
    schedule: {},
    token: null,
    sedex: {
      email: "",
      cep: "",
      address: "",
      number: "",
      district: "",
      city: "",
      complement: "",
      state: "",
      obs: "",
      recipientName: "",
      lot: []
    }
  },
  actions,
  mutations,
  getters,
  modules: {
    lot: Lot,
    after_sales: AfterSales,
    storage: uploadDocuments,
    delivery: Delivery,
    payment: Payment,
    salaryOccupation: SalaryOccupation,
    certDetran: certDetran
  },
  plugins: [createPersistedState({
    paths: ['delegation', 'user', 'userAddress', 'lot', 'schedule', 'after_sales', 'token', 'sedex', 'payment', 'storage']
  })]
})
