import UserService from "../../../services/requests/UserService";
import { GET_DATA_CERT_DETRAN, STORE_QUEUE_SCRAP } from "../actions/certDetran";

const state = {
  loading: false,
  isSubmitting:  false,
  error: false,
  file: null
};

const actions = {
  [STORE_QUEUE_SCRAP]: ({ commit }, formData) => {
    commit('isSubmitting', true);
    commit('error', false);
    return new Promise((resolve, reject) => {
      const service = new UserService;
      service.storeQueueScraping(formData)
      .then(response => {
        const data = response.data.data;

        commit('file', {
          status: { "id": 2, "name": "Aguardando Aprovação"},
          observation: null,
          validate: formData.get('expires_at')
        });

        resolve(data);
      })
      .catch(err => {
        const error = err.response.data.error;
        commit('error', error);
        reject(error.message);
      })
      .finally(() => {
        commit('isSubmitting', false);
      })
    });
  },

  [GET_DATA_CERT_DETRAN]: ({ commit }) => {
    commit('loading', true);
    return new Promise((resolve, reject) => {
      const service = new UserService;
      service.getCertDetran()
      .then(response => {
        const data = response.data.data;
        commit('file', data);
        resolve(response.data.data);
      })
      .catch(err => {
        const error = err.response.data.error;
        commit('error', error);
        reject(error);
      })
      .finally(() => {
        commit('loading', false);
      })
    })
  }
};

const mutations = {
  loading: (state, value) => state.loading = value,
  error: (state, value) => state.error = value,
  file: (state, value) => state.file = value,
  isSubmitting: (state, value) => state.isSubmitting = value,
};

const getters = {
  loading: (state) => state.loading,
  error: (state) => state.error,
  file: (state) => state.file,
  isSubmitting: (state) => state.isSubmitting
};


export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
