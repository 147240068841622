import Api from "../Api";

class UserAddressService {
  async getMyAdresses(userId) {
    return await Api.get("/user/address" + "?buyerIdChosen=" + userId);
  }

  async getMainAdresses() {
    return await Api.get("/user/main-address");
  }

  async saveAdresses(address) {
    return await Api.post("/user/address", address);
  }

  async updateWebAddress(address) {
    return await Api.put("/user/web-address", address);
  }
}

export default UserAddressService;
