import store from './../store/index';

export default {
  deleteVuex() {
    store.dispatch("setDelegation", {});
    store.dispatch("setAlert", { show: false });
    store.dispatch("setUserLogged", {});
    store.dispatch("setSchedule", {});
    store.dispatch("setToken", null);
    store.dispatch("setUserAddressLogged", null);
  },
}
